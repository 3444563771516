import TableStyles from "../../../styles/TableStyles.css";
import CommonStyles from "../../../styles/CommonStyles.css";

export const styles = (theme) => ({
  ...TableStyles,
  ...CommonStyles,
  
  globalHeader:{
    display: "flex",
    background: "#292929",
    alignItems: "center",
    zIndex: "8",
    position: "relative",
    padding: "13px 5px",
    "& span":{
      wordBreak:" break-word",
    }
  },
  PlaylitStatusDropMenu:{
    display: "flex",
    background: "#4A4A4A",
    borderRadius: "4px",
    padding: "4px",
    width:"100%",
    justifyContent:"flex-start",
    "&:hover":{
      background: "#333333",
    },
    "& span.MuiButton-label":{
      "& .trafficLight":{
        minWidth:"22px",
      },
      "& > span":{
      display:"flex",
      alignItems: "center",
      },
      "& span":{
        color:"#fff !important",
        fontSize:"12px",
        lineHeight: "1",
        textTransform: "none",
        justifyContent: "space-between",
        display: "flex",
        width: "100%",
      },
      "& .MuiSvgIcon-root":{
        color:"#fff !important"
      },
      "& .MuiCircularProgress-root":{
        marginLeft:"5px",
        width:"15px !important",
        height:"15px  !important"
      },
      "& .MuiCircularProgress-svg":{
        color:"#fff !important"
      }
    }
  },
  sharableCount:{
    fontSize:"14px",
    display: "flex",
    color: "#ffffff",
    fontWeight: "bold",
    marginRight: "10px",
    alignItems: "center",
    "& .MuiCircularProgress-colorPrimary":{
      width: "15px !important",
      height: "15px!important",
      background:"none",
      "& .MuiCircularProgress-svg":{
      color:"#fff"
      }
    },
    "& div":{
      background:" #1e1e1e",
      padding: "5px",
      borderRadius: "5px",
      color: "#fff",
      marginLeft: "10px"
    }
  },
  playlistCounts:{
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-end",
  },
  globalBlock:{
    marginBottom:"10px", 
    "& > div":{
      padding:"5px 10px",
    },
    "& button.MuiButton-text":{
      margin:"15px 0",
    },
    "&  .MuiOutlinedInput-root":{
      background: "#2d2d2d",
    },
  },
  roledividerMargin:{
    margin:"10px 10px  20px 10px ",
  },
  globalModuleWrap:{
    paddingTop:"20px",
    paddingLeft:"20px",
  },
  
 moduleEdit:{
  float:"right",
  minWidth: "30px",
  background: "#212121",
  padding:"8px",
},
sidBarWrap:{
  // minWidth:"480px",
  position:"relative",
  overflow:"hidden",
  height:"100%",
  background:"#303030",
},
 
sidBarInnerWrap:{
  left:" 0",
  width: "100%",
  height: "calc(100% - 0px)",
  position: "absolute",
  overflowX: "hidden",
  overflowY: "auto",
},
sidBarInnerContents:{
  padding: "20px",
},
sidbarDropdownFull:{
  marginBottom:"25px",
  "& .MuiSelect-select":{
    background:" #2d2d2d",
    padding: "9px",
     fontSize:"1em",
    border:"1px solid #5e5e5e",
  },
  "& .MuiOutlinedInput-notchedOutline":{
    borderColor:"transparent  !important",
  },
  "& .MuiAutocomplete-inputRoot":{
    background:" #2d2d2d",
     fontSize:"1em",
    padding: "6px 10px",
    borderRadius: "4px",
    border:"1px solid #5e5e5e",
  },
  "& div:before": {
    outline: "none",
    border: "none !important",
    width: "100%",
},
"& .MuiInput-underline:after": {
    borderBottom: "2px solid #0C853E",
},
"& input": {
    width: "100%",
    padding: "5px 8px",
     fontSize:"1em",
    fontWeight: "300",
},
"& .MuiAutocomplete-endAdornment":{
  top: "calc(50% - 14px)",
  right: "6px",
  position: "absolute",
},
}, 
// Users
recipientLabel: {
  border: "1px solid rgba(81, 81, 81, 1)",
  borderRadius: "18px",
  padding: "0px",
  margin: "0px 1px 0px 0px",
  background: "#212121",
  display: "flex",
  alignItems:"center",
  fontSize: "0.7em",
  "& button":{
    minWidth: "30px",
    padding:"6px",
    borderRadius: "50%",
  },
  "& .MuiAvatar-root":{
    borderRadius: "50px !important",
  }
},
addUsersButton:{
    background: "#0C853E",
    boxShadow: "none",
    color: "#fff",
    textTransform: "none",
    fontWeight: "400",
    margin: "20px 0 10px 0",
    fontSize:"12px",
    "&:hover": {
        background: "#1a1a1a",
        color: "#29D211",
    },
    "& svg":{
      fontSize: "19px",
    },

},
smallAvatar: {
  margin: "4px",
  width: theme.spacing(3),
  height: theme.spacing(3),
},
sharedUserName: {
  width: "60px",
  overflow: "hidden",
  textOverflow: "ellipsis",
  fontSize: "0.8em",
  height: "15px",
},
sharedUserEmail: {
  width: "80px",
  overflow: "hidden",
  textOverflow: "ellipsis",
  fontSize: "0.8em",
  color: "#8A8A8A",
  whiteSpace:"nowrap",
  marginRight:"5px"
},
cancelButtonRoot: {
  minWidth: "0px",
  padding: "0px",
  margin: "0px 7px 0px 5px",
  borderRadius: "10px",
},
closeIconRoot: {
  fontSize: "20px",
  width: "13px",
},
tableMainView: {
  overflow: "auto",
  width: "100%",
  marginTop: "54px",
  background: "#000",
  position: "absolute",
  height: "calc(100% - 61px)"
},


playlistCreateContentWrapper:{
  "& .MuiInputBase-fullWidth":{
    marginBottom: "0",
    }
},
avatarImage:{
  border:"1px solid #636363",
},
dialogWrapper:{
  fontSize:"12px",
},
dialogYesButton:{
  background:"#118543",
  "&:hover":{
    background:"#000",
    color:"#118543",
  }
},
dialogNoButton:{
  background:"#505050",
  "&:hover":{
    background:"#000",
    color:"#fff",
  }
},
emptyImage:{
  border:" 1px solid #3b3b3b",
  borderRadius: "3px",
  width: "80%",
  margin: "0 auto",
  padding: "10px",
  textAlign: "center",
  fontSize: "50px",
  minHeight: "188px",
  display: "block",
  lineHeight: "169px",
  color: "#e6e6e6",
  display: "flex",
    alignItems: "center",
    justifyContent: "center",
},
emptyImageHeaderImage:{
  border:"1px solid #3b3b3b",
  borderRadius: "3px",
  padding: "6px 7px 1px 7px",
  textAlign: "center",
  fontSize: "22px",
  color: "#e6e6e6",
    margin: "0 15px 0 12px",
},
Muierror:{
  margin: "0",
  color: "#FFD1D1",
    marginTop: "5px",
},
stationList:{
  display:"block",
  "& .MuiFormControlLabel-root":{
    display:"inline-block",
    verticlAlign:"top",
    width: "32%",
  }
},
trackCount:{
  position: "absolute",
    left: "20px",
},
emptyAssets:{
  color:"#9f9f9f"
},
stationList:{
  display:"block",
  "& .MuiFormControlLabel-root":{
    display:"inline-block",
    verticlAlign:"top",
    width: "32%",
  }
},
searchDropdownList: {
  background: "#3B3B3B",
  borderRadius: "4px",
  padding: "0px",
  fontSize:"1em",
  "& .MuiAutocomplete-tag":{
    fontSize:"12px",
    margin: "2px",
  },
  "& .MuiIconButton-root":{
    padding: "1px !important",
    fontSize: "10px !important",
  },
 "& .MuiSvgIcon-fontSizeSmall":{
    fontSize: "1.2rem",
},
  "& .MuiInput-underline": {
    minHeight: "34px",
  },
  "& .MuiTextField-root": {
    margin: "0px",
  },
  "& .MuiInput-underline:hover:not($disabled):before": {
    borderBottom: "1px solid #00D056 !important",
  },
  "& .MuiInput-underline:after": {
    borderBottom: "1px solid #00D056",
  },
  "& input":{
    fontSize:" 0.8em",
    padding: "6px 0!important",
  },
  "& .MuiInput-underline:before":{
    display:"none"
  },
  "& .MuiInput-underline:after":{
    display:"none"
  },
  "& .MuiInput-root":{
    fontSize:"0.8em",
  },
  "& .MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline":{
    borderColor:"#46af22 !important",
  }
},
sharePlaylistUsersLabel:{
  color: "#dcdcdc",
    fontSize: "12px",
    textTransform: "uppercase",
    verticalAlign: "top",
    margin:"15px 0 8px 0",
},
tableFilterDateInput:{
  marginTop: "0",
    marginBottom: "0",
    "& .MuiInput-underline:after":{
      display:"none",
    },
    "& .MuiInput-underline:before":{
      display:"none",
    },
    "& .MuiInput-underline":{
      border: "1px solid rgba(255, 255, 255, 0.23)",
      "&:hover":{
        borderColor: "#46af22",
      },
      "&:focus":{
        borderColor: "#46af22",
      }
    },
    "& .Mui-focused":{
      borderColor: "#46af22",
    },
    "& .MuiInputAdornment-root":{
      width:"auto",
      marginRight:"3px",
      "& svg":{
        width:"18px",
      }
    },
    "& input":{
      background:"#2d2d2d",
      border:"1px solid #5e5e5e",
      padding:"10px !important"
    }
},
playlistChips:{
  "& .MuiChip-root":{
    maxWidth:"200px",
    margin:"5px 1px 1px 1px",
  }
},
chartTypeIcon:{
  height:"34px",
  width: "33px",
  lineHeight: "19px",
},

tableFilterDate:{
  marginTop: "0",
    marginBottom: "0",
    "& .MuiInput-underline":{
      border:"transparent !important",
    },  
    "& input":{
      padding:"8px 5px 5px 33px !important",
    },
    "& .MuiInput-underline:after":{
      display:"none",
    },
    "& .MuiInput-underline:before":{
      display:"none",
    },
    "& .MuiInput-underline":{
      border: "1px solid rgb(76 76 76) !important",
      background:"transparent !important",
      "& button":{
        borderRadius:"5px",
      },
      "& .MuiButton-root":{
        width:"30px",
        height:"30px",
      },
      "& .MuiIconButton-root":{
        width:"30px",
        height:"30px",
      },
      "&:hover":{
        borderColor: "#46af22",
      },
      "&:focus":{
        borderColor: "#46af22",
      }
    },
    "& .Mui-focused":{
      borderColor: "#46af22",
    },
    "& .MuiInputAdornment-root":{
      width:"auto",
      marginRight:"3px",
      "& svg":{
        width:"18px",
      }
    },
    "& .Mui-disabled .MuiInputAdornment-root":{
      marginRight: "28px",
    },
},
tableFilterDateRange:{
  display: "flex",
  alignItems: "flex-start",
  background: "#4c4c4c",
    "& .DateRangeButton .MuiButton-text":{
      marginRight: "25px",
      marginTop: "3px",
      marginLeft: "3px"
   },
    "& .MuiButton-root":{
      width: "30px",
      height: "30px",
      minWidth:"inherit",
      borderRadius: "5px",
      marginTop:"0",
    },
},

});
